export enum PaymentStates {
	CODE_ACTIVATED = 'code-activated',
	OPEN = 'open',
	PAID = 'paid',
	FAILED = 'failed',
}

export interface Payment {
	payment: {
		paymentUrl: string;
		status: PaymentStates;
	};
	isPremium: boolean;
}
