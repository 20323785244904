export enum TaskErrorCodes {
	UNKNOWN = 'ERROR_UNKNOWN',
	TASK_NOT_FOUND = 'ERROR_TASK_30002',
}

export enum TaskStatus {
	TO_DO = 'TODO',
	IN_PROGRESS = 'BUSY',
	PENDING = 'WAITING',
	DONE = 'DONE',
	READ = 'READ',
	NOT_APPLICABLE = 'NOT_NECESSARY',
}

export interface TaskGroup {
	category: TaskCategory;
	tasks: Task[];
}

export interface Task {
	categoryId: number;
	guid: string;
	title: string;
	description: string;
	infoURL?: string;
	productURL?: string;
	status: TaskStatus;
	closestDeadline: string;
	legalDeadline: string;
	personalDeadline: string;
}

export interface TaskCategory {
	id: number;
	name: string;
	description: string;
	sortOrder: number;
	options?: {
		translate?: boolean;
	};
	isCompleted: boolean;
}
