import { Task, TaskCategory } from './task.interface';

export enum DeceasedErrorCodes {
	UNKNOWN = 'ERROR_UNKNOWN',
	PREMIUM_CODE_INVALID = 'ERROR_PAYMENT_40001',
	PREMIUM_CODE_EXPIRED = 'ERROR_PAYMENT_40002',
	// DECEASED_IS_ALREADY_PREMIUM = 'ERROR_PAYMENT_40003',
}

export interface Deceased {
	firstname: string;
	lastname: string;
	birthdate: string;
	deathdate: string;
	tasks: Array<Task>;
	categories: Array<TaskCategory>;
}
