import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, computed, signal } from '@angular/core';
import { ApiErrorHelper } from '@nx/core/lib/helpers/api-error/api-error.helper';
import {
	Deceased,
	DeceasedErrorCodes,
} from '@nx/core/lib/interfaces/deceased.interface';
import {
	LoaderState,
	LoadingStates,
} from '@nx/core/lib/interfaces/loading-states.interface';
import {
	Payment,
	PaymentStates,
} from '@nx/core/lib/interfaces/payment.interface';
import {
	Task,
	TaskCategory,
	TaskErrorCodes,
	TaskStatus,
} from '@nx/core/lib/interfaces/task.interface';
import {
	AccountDeceasedPaymentRequestAPI,
	AccountDeceasedPaymentStatusResponseAPI,
	AccountDeceasedResponseAPI,
	CategoryAPI,
	TaskAPI,
	TaskResponseAPI,
	UpdateTaskRequestAPI,
} from 'api-interfaces';
import { Observable, catchError, map, of } from 'rxjs';

/* States taken from https://docs.mollie.com/payments/status-changes */
export enum MolliePaymentStates {
	OPEN = 'open',
	CANCELED = 'canceled',
	PENDING = 'pending',
	AUTHORIZED = 'authorized',
	EXPIRED = 'expired',
	FAILED = 'failed',
	PAID = 'paid',
}

@Injectable({
	providedIn: 'root',
})
export class DeceasedService {
	private _deceasedStateSig = signal<LoaderState<Deceased, string>>({
		state: LoadingStates.IDLE,
	});
	deceasedStateComputed = computed(() => this._deceasedStateSig());

	private _premiumStateSig = signal<LoaderState<Payment, string>>({
		state: LoadingStates.IDLE,
	});
	premiumStateComputed = computed(() => this._premiumStateSig());

	private _taskStateSig = signal<LoaderState<Task, string>>({
		state: LoadingStates.IDLE,
	});
	updateTaskStateComputed = computed(() => this._taskStateSig());

	private _paymentStateSig = signal<LoaderState<Payment, string>>({
		state: LoadingStates.IDLE,
	});
	paymentStateComputed = computed(() => this._paymentStateSig());

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	getDeceased(data: {
		deceasedID: string;
	}): Observable<Deceased | undefined> {
		this._deceasedStateSig.set({
			state: LoadingStates.LOADING,
		});

		const params: HttpParams = new HttpParams({
			fromObject: {},
		});
		return this.http
			.get<AccountDeceasedResponseAPI>(
				`${this.endpoint}v1/account/deceased/${data.deceasedID}`,
				{
					params,
				},
			)
			.pipe(
				map((response) => {
					const data = this.parseDeceased(response);
					this._deceasedStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});
					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<DeceasedErrorCodes>(
						errorResponse,
						DeceasedErrorCodes,
					);

					this._deceasedStateSig.set({
						state: LoadingStates.ERROR,
						error: error,
					});

					return of(undefined);
				}),
			);
	}

	activatePremium(data: {
		deceasedID: string;
		code?: string;
	}): Observable<Payment | undefined> {
		this._premiumStateSig.set({
			state: LoadingStates.LOADING,
		});

		const body: AccountDeceasedPaymentRequestAPI = {
			...(data.code ? { premiumcode: data.code } : {}),
		};
		return this.http
			.post<AccountDeceasedPaymentStatusResponseAPI>(
				`${this.endpoint}v1/account/deceased/${data.deceasedID}/payment`,
				body,
			)
			.pipe(
				map((response) => {
					const data = this.parsePayment(response);
					this._premiumStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});
					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<DeceasedErrorCodes>(
						errorResponse,
						DeceasedErrorCodes,
					);

					this._premiumStateSig.set({
						state: LoadingStates.ERROR,
						error: error,
					});

					return of(undefined);
				}),
			);
	}

	updateTask(
		guid: string,
		data: {
			status?: TaskStatus;
			personalDeadline?: string;
		},
	): Observable<Task | undefined> {
		this._taskStateSig.set({
			state: LoadingStates.LOADING,
		});

		const body: UpdateTaskRequestAPI = {
			...(data.status ? { state: data.status } : {}),
			...(data.personalDeadline !== undefined
				? { personalDeadline: data.personalDeadline }
				: {}),
		};
		return this.http
			.patch<TaskResponseAPI>(`${this.endpoint}v1/task/${guid}`, body)
			.pipe(
				map((response) => {
					const data = this.parseTask(response.data);
					this._taskStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});
					this._deceasedStateSig.update((state) =>
						this.updateTaskOfDeceased(state, data),
					);
					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<TaskErrorCodes>(
						errorResponse,
						TaskErrorCodes,
					);

					this._taskStateSig.set({
						state: LoadingStates.ERROR,
						error: error,
					});

					return of(undefined);
				}),
			);
	}

	verifyPayment(deceasedID: string): Observable<Payment | undefined> {
		this._paymentStateSig.set({
			state: LoadingStates.LOADING,
		});

		return this.http
			.get<AccountDeceasedPaymentStatusResponseAPI>(
				`${this.endpoint}v1/account/deceased/${deceasedID}/payment/status`,
			)
			.pipe(
				map((response) => {
					const data = this.parsePayment(response);
					this._paymentStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});
					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<DeceasedErrorCodes>(
						errorResponse,
						DeceasedErrorCodes,
					);

					this._paymentStateSig.set({
						state: LoadingStates.ERROR,
						error: error,
					});

					return of(undefined);
				}),
			);
	}

	resetPremiumState() {
		this._premiumStateSig.set({
			state: LoadingStates.IDLE,
		});
	}

	private updateTaskOfDeceased(
		state: LoaderState<Deceased, string>,
		task: Task,
	) {
		state.data?.tasks.forEach((element) => {
			if (element.guid === task.guid) {
				Object.assign(element, task);
			}
		});
		return state;
	}

	private parseDeceased(response: AccountDeceasedResponseAPI): Deceased {
		return {
			firstname: response.data.firstname || '',
			lastname: response.data.lastname || '',
			birthdate: response.data.birthdate || '',
			deathdate: response.data.deathdate || '',
			tasks:
				response.data.tasks?.map(
					(task): Task => this.parseTask(task),
				) || [],
			categories:
				response.data.categories?.map(
					(category): TaskCategory => this.parseCategories(category),
				) || [],
		};
	}

	private parseTask(task: TaskAPI): Task {
		return {
			categoryId: task.categoryID,
			closestDeadline: task.closestDeadline,
			legalDeadline: task.legalDeadline,
			personalDeadline: task.personalDeadline,
			guid: task.guid,
			status: (task.state as TaskStatus) || TaskStatus.TO_DO,
			description: task.description,
			title: task.title,
			infoURL: task.infoURL,
			productURL: task.productURL,
		};
	}

	private parseCategories(category: CategoryAPI): TaskCategory {
		return {
			id: category.id,
			name: category.name,
			description: category.description,
			sortOrder: category.sequenceNumber,
			isCompleted: category.completed,
		};
	}

	private parsePayment(
		response: AccountDeceasedPaymentStatusResponseAPI,
	): Payment {
		let status = PaymentStates.OPEN;

		switch (response.data?.payment?.status) {
			case MolliePaymentStates.OPEN:
			case MolliePaymentStates.PENDING:
			case MolliePaymentStates.AUTHORIZED:
				status = PaymentStates.OPEN;
				break;
			case MolliePaymentStates.CANCELED:
			case MolliePaymentStates.EXPIRED:
			case MolliePaymentStates.FAILED:
				status = PaymentStates.FAILED;
				break;
			case MolliePaymentStates.PAID:
				status = PaymentStates.PAID;
				break;
		}

		return {
			isPremium: response.data?.isPremium || false,
			payment: {
				paymentUrl: response.data?.payment?.url || '',
				status: status,
			},
		};
	}
}
