import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DeceasedService } from './deceased.service';

@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [DeceasedService],
})
export class ProviderDeceasedModule {}
